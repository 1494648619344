
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CallService } from '../call.service';
import { CallInfoDialogComponents, DialogData } from '../dialog/callinfo-dialog.component';

@Component({
  selector: 'app-video-host',
  templateUrl: './video-host.component.html',
  styleUrls: ['./video-host.component.scss']
})
export class VideoHostComponent implements OnInit {


  public isCallStarted$: Observable<boolean>;
  private peerId: string;
  private audio = true;
  private video = true;
  private faceingUser = true;

  @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;

  constructor(public dialog: MatDialog, private callService: CallService) {
    this.isCallStarted$ = this.callService.isCallStarted$;
    this.peerId = this.callService.initPeer('1234');
  }
  
  ngOnInit(): void {
    this.callService.localStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.localVideo.nativeElement.srcObject = stream)
    this.callService.remoteStream$
      .pipe(filter(res => !!res))
      .subscribe((stream) => {this.remoteVideo.nativeElement.srcObject = stream; console.log('host received remote stream ', stream)});
  }
  
  ngOnDestroy(): void {
    this.callService.destroyPeer();
  }

  public showModal(joinCall: boolean): void {
    let dialogData: DialogData = joinCall ? ({ peerId: null, joinCall: true }) : ({ peerId: this.peerId, joinCall: false });
    const dialogRef = this.dialog.open(CallInfoDialogComponents, {
      width: '250px',
      data: dialogData
    });

    dialogRef.afterClosed()
      .pipe(
        switchMap(peerId => 
          // this.callService.enableCallAnswer()
          this.enableCallAnswer()
        ),
      )
      .subscribe(_  => { });
  }
  
  public endCall() {
    this.callService.closeMediaCall();
    this.callService.destroyPeer();
  }

  private async enableCallAnswer(){
    this.callService.enableCallAnswer().then(()=>{
      this.callService.hostVideoCallDisconnected().subscribe((data) => {
        console.log(" close call , callback received ", data);
      });
    });

  }

  public async switchCamera() {
    let facingMode = 'user';
    if( this.faceingUser ){
      this.faceingUser = !this.faceingUser;
      facingMode = 'environment'
    } else {
      this.faceingUser = !this.faceingUser;
      facingMode = 'user'
    }
    this.localVideo.nativeElement.srcObject = await this.callService.switchCamera(facingMode);
  }

  public toggleAudio(){
    this.audio = ! this.audio;
    if(this.audio){
      this.callService.unmuteAudio()
    } else {
      this.callService.muteAudio();
    }
  }

  public toggleVideo(){
    this.video = ! this.video;
    if( this.video ){
      this.callService.unmuteVideo();
    } else {
      this.callService.muteVideo();
    }
  }
}
