import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Peer, MediaConnection, PeerJSOption} from 'peerjs';
import { BehaviorSubject, Subject, Observable, Observer} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';


@Injectable()
export class CallService {

    // private peer: Peer;
    private peer:Peer;
    private mediaCall:MediaConnection;
  
    private localStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject(null);
    public localStream$ = this.localStreamBs.asObservable();
    private remoteStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject(null);
    public remoteStream$ = this.remoteStreamBs.asObservable();

    private isCallStartedBs = new Subject<boolean>();
    public isCallStarted$ = this.isCallStartedBs.asObservable();

    private connection:any;

    constructor(private snackBar: MatSnackBar) { }

    public initPeer(id): string {
        if (!this.peer || this.peer.disconnected) {
            const peerJsOptions: PeerJSOption = {
                // host: "localhost",
                host: "dev.peer.degpeg.com",
                port: 9050,
                path: "degpeg",
                debug: 1,
                // secure: true,
                config: {
                    iceServers: [
                        {
                            urls: [
                                'stun:stun1.l.google.com:19302',
                                'stun:stun2.l.google.com:19302',
                            ],
                        
                        },
                        // {
                        //     urls: 'turn:turn.anyfirewall.com:443?transport=tcp',
                        //     credential: 'webrtc',
                        //     username: 'webrtc'
                        // },
                        // { 
                        //     urls: "turn:3.109.121.40:3478",
                        //     username: "test",
                        //     credential: "test123", 
                        // },
                        // { 
                        //     urls: "turn:openrelay.metered.ca:80",
                        //     username: "openrelayproject",
                        //     credential: "openrelayproject", 
                        // },
                        {
                            urls: 'turn:numb.viagenie.ca',
                            credential: 'muazkh',
                            username: 'webrtc@live.com'
                        },
                        // {
                        //     urls: 'turn:192.158.29.39:3478?transport=udp',
                        //     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
                        //     username: '28224511:1379330808'
                        // },
                        // {
                        //     urls: 'turn:192.158.29.39:3478?transport=tcp',
                        //     credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
                        //     username: '28224511:1379330808'
                        // }
                    ]
                }
            };
            try {
                let sessionId = id;
                console.log('id: ', sessionId, "options: ", peerJsOptions);
                this.peer = new Peer(sessionId, peerJsOptions);
                return sessionId;
            } catch (error) {
                console.error(error);
            }
        }
    }

    public async establishMediaCall(remotePeerId: string) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            // const connection = this.peer.connect(remotePeerId);
            this. connection = this.peer.connect(remotePeerId);

            this.connection.on('error', err => {
                console.error(err);
                // this.snackBar.open(err, 'Close');
            });

            this.mediaCall = this.peer.call(remotePeerId, stream);
            if (!this.mediaCall) {
                let errorMessage = 'Unable to connect to remote peer';
                this.snackBar.open(errorMessage, 'Close');
                throw new Error(errorMessage);
            }
            this.localStreamBs.next(stream);
            this.isCallStartedBs.next(true);

            this.mediaCall.on('stream',
                (remoteStream) => {
                    console.log('recieved remote stream in call service:', remoteStream);
                    this.remoteStreamBs.next(remoteStream);
                });
            this.mediaCall.on('error', err => {
                // this.snackBar.open(err, 'Close');
                console.error(err);
                this.isCallStartedBs.next(false);
            });
            this.mediaCall.on('close', () => this.onCallClose());
        }
        catch (ex) {
            console.error(ex);
            this.snackBar.open(ex, 'Close');
            this.isCallStartedBs.next(false);
        }
    }

    public async enableCallAnswer() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            this.localStreamBs.next(stream);
            this.peer.on('call', async (call) => {
    
                this.mediaCall = call;
                this.isCallStartedBs.next(true);
    
                this.mediaCall.answer(stream);
                this.mediaCall.on('stream', (remoteStream) => {
                    console.log('recieved remote stream in call service:', remoteStream);
                    this.remoteStreamBs.next(remoteStream);
                });
                this.mediaCall.on('error', err => {
                    // this.snackBar.open(err, 'Close');
                    this.isCallStartedBs.next(false);
                    console.error(err);
                });
                this.mediaCall.on('close', () => this.onCallClose());
            });   

                   
        }
        catch (ex) {
            console.error(ex);
            this.snackBar.open(ex, 'Close');
            this.isCallStartedBs.next(false);            
        }
    }

    private onCallClose() {
        this.remoteStreamBs?.value.getTracks().forEach(track => {
            track.stop();
        });
        this.localStreamBs?.value.getTracks().forEach(track => {
            track.stop();
        });
        this.snackBar.open('Call Ended', 'Close');
    }

    public closeMediaCall() {
        this.mediaCall?.close();
        if (!this.mediaCall) {
            this.onCallClose()
        }
        this.isCallStartedBs.next(false);
    }

    public destroyPeer() {
        console.log('destroy peer called');
        this.mediaCall?.close();
        this.peer?.disconnect();
        this.peer?.destroy();
    }

    public async switchCamera(facingMode){
        // 
        const options = {
            audio: false,
            video: {
                facingMode: facingMode, // 'user' Or 'environment'
            },
        };
        const newStream = await navigator.mediaDevices.getUserMedia(options);
        const newVideoTrack = newStream.getVideoTracks()[0];
        const pc = this.mediaCall.peerConnection;
        const sender = this.mediaCall.peerConnection.getSenders().find((s) => s.track.kind === 'video');
        console.log('sender before replacement: ', sender.track);
        sender.replaceTrack(newVideoTrack);
        const senderNew = this.mediaCall.peerConnection.getSenders().find((s) => s.track.kind === 'video');
        console.log('sender after replacement: ', senderNew.track);
        return newStream;
        
    }

    public muteAudio(){
        console.log('mute audio: initial value: ', this.localStreamBs.value);
        if( this.localStreamBs.value){
            this.localStreamBs.value.getAudioTracks()[0].enabled = false;
        }
    }

    public unmuteAudio(){
        console.log('unmute audio: initial value: ', this.localStreamBs.value);
        if( this.localStreamBs.value){
            this.localStreamBs.value.getAudioTracks()[0].enabled = true;
        }
    }

    public muteVideo(){
        console.log('mute video: initial value: ', this.localStreamBs.value);
        if( this.localStreamBs.value){
            this.localStreamBs.value.getVideoTracks().forEach((track)=> {track.enabled = false});
            // this.localStreamBs.value.getVideoTracks()[0].enabled = false;
        }
    }

    public unmuteVideo(){
        console.log('unmute video: initial value: ', this.localStreamBs.value);
        if( this.localStreamBs.value){
            this.localStreamBs.value.getVideoTracks().forEach((track)=> {track.enabled = true});
            // this.localStreamBs.value.getVideoTracks()[0].enabled = true;
        }
    }


    hostVideoCallDisconnected(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.peer.on('connection', conn => {
                console.log('peer on connection received');
                conn.on('close', () => {
                    console.log("conn close event received");
                    observer.next('null');
                });
            }); 
        });
    }

    guestVideoCallDisconnected(): Observable<any> {
        return new Observable((observer: Observer<any>) => {

            this.connection.on('close', () => {
                console.log("conn close event received");
                observer.next('null');
            });

        });
    }

}