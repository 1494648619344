<div class="container-fluid">
    <div class="row p-2">
      <button mat-raised-button color="primary" class="m-2" (click)="switchCamera()">switch camera</button>
      <button mat-raised-button color="primary" class="m-2" [disabled]="isCallStarted$ | async" (click)="showModal(false)">Start Call</button>
      <!-- <button mat-raised-button class="m-2" [disabled]="isCallStarted$ | async" (click)="showModal(true)">Join Call</button> -->
      <button mat-raised-button color="warn" class="m-2" [disabled]="!(isCallStarted$ | async)" (click)="endCall()">End Call</button>
      <button mat-raised-button color="secondary" class="m-2" [disabled]="!(isCallStarted$ | async)" (click)="toggleAudio()">Toggle Audio</button>
      <button mat-raised-button color="secondary" class="m-2" [disabled]="!(isCallStarted$ | async)" (click)="toggleVideo()">Toggle Video</button>
    </div>
    <div class="row">
      <div class="col text-center">
        <div id="video-wrapper">
          <video #remoteVideo id="remote-video" poster="./assets/video-placeholder.jpg" autoplay playsinline></video>
          <video #localVideo id="local-video" autoplay playsinline [muted]=true></video>
        </div>
      </div>
    </div>
  </div>