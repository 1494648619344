import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoGuestComponent } from './video-guest/video-guest.component';
import { VideoHostComponent } from './video-host/video-host.component';

const routes: Routes = [
  {
    path: "guest",
    component: VideoGuestComponent,
  },
  {
    path: "host",
    component: VideoHostComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
